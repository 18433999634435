import { CONSTANT } from "@/helpers/constant"

export default {
  path: "/",
  component: () => import("@/views/sales-force/SalesForceIndex.vue"),
  children: [
    {
      path: CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE,
      name: CONSTANT.ROUTE.NAME.DASHBOARD_SALES_FORCE,
      component: () => import("@/views/sales-force/SalesForceDashboard.vue"),
    },
    {
      path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_INDEX,
      name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_INDEX,
      redirect: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA,
      component: () => import("@/views/sales-force/CustomerDataIndex.vue"),
      children: [
        {
          path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA,
          name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA,
          redirect: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_BASE,
          component: () => import("@/views/sales-force/customer-data/CustomerData.vue"),
          children: [
            {
              path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_CREATE,
              name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_CREATE,
              component: () => import("@/views/sales-force/customer-data/CustomerDataGeneralIndex.vue"),
            },
            {
              path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_BASE,
              name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_BASE,
              component: () => import("@/views/sales-force/customer-data/CustomerDataGeneralIndex.vue"),
            },
            {
              path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_ADDRESSES,
              name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_ADDRESSES,
              component: () => import("@/views/sales-force/customer-data/CustomerDataAddresses.vue"),
            },
            {
              path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_ORDER,
              name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_ORDER,
              component: () => import("@/views/sales-force/customer-data/CustomerDataOrders.vue"),
            },
            {
              path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_ORDER_DETAIL,
              name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_ORDER_DETAIL,
              component: () => import("@/views/sales-force/customer-data/CustomerDataOrderView.vue"),
            },
            {
              path: CONSTANT.ROUTE.PATH.SALES_FORCE_CUSTOMER_DATA_DOCUMENTS,
              name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_DOCUMENTS,
              component: () => import("@/views/sales-force/customer-data/CustomerDataDocuments.vue"),
            },
          ],
        },
        {
          path: CONSTANT.ROUTE.PATH.SALES_FORCE_ORDER_CATALOGUE,
          name: CONSTANT.ROUTE.NAME.SALES_FORCE_ORDER_CATALOGUE,
          component: () => import("@/views/sales-force/OrderCatalogue.vue"),
        },
        {
          path: CONSTANT.ROUTE.PATH.SALES_FORCE_PRODUCT_RANGE,
          name: CONSTANT.ROUTE.NAME.SALES_FORCE_PRODUCT_RANGE,
          component: () => import("@/views/sales-force/ProductRange.vue"),
        },
        {
          path: CONSTANT.ROUTE.PATH.SALES_FORCE_PRODUCT_RANGE_DETAILS,
          name: CONSTANT.ROUTE.NAME.SALES_FORCE_PRODUCT_RANGE_DETAILS,
          component: () => import("@/views/sales-force/ProductRangeDetail.vue"),
        },
      ],
    },
  ],
}
