import { CONSTANT } from "@/helpers/constant"

const lettersWithAcute: any = CONSTANT.PLAYER_CONFIGURATIONS.LETTERS_WITH_ACUTE
const lettersWithGrave: any = CONSTANT.PLAYER_CONFIGURATIONS.LETTERS_WITH_GRAVE
const lettersWithCircumflex: any = CONSTANT.PLAYER_CONFIGURATIONS.LETTERS_WITH_CIRCUMFLEX
const letters = CONSTANT.PLAYER_CONFIGURATIONS.SMALL_CAPS_LETTERS
const specialCharacters = CONSTANT.PLAYER_CONFIGURATIONS.ALLOWED_SPECIAL_CHARACTERS
const germanUmlauts = CONSTANT.PLAYER_CONFIGURATIONS.GERMAN_UMLAUTS
const frenchTremaCedilla = CONSTANT.PLAYER_CONFIGURATIONS.FRENCH_TREMA_CEDILLA

const restrictCharsDirective = {
  beforeMount(el: HTMLInputElement) {
    const allowedCharacters: any = [].concat.apply([], [lettersWithAcute, lettersWithGrave, lettersWithCircumflex, letters, specialCharacters, germanUmlauts, frenchTremaCedilla])
    el.addEventListener("keypress", (event) => {
      const char = event.key
      if (!allowedCharacters.includes(char)) {
        event.preventDefault()
      }
    })
    el.addEventListener("input", (event: any) => {
      if (event && event.target && event.target?.value) {
        const inputText = event.target?.value
        let isInvalidText = false
        for (let i = 0; i < inputText.length; i++) {
          if (!allowedCharacters.includes(inputText[i])) {
            isInvalidText = true
            break
          }
        }
        if (isInvalidText) {
          event.target.value = event.target.value.slice(0, -1)
          event.preventDefault()
        }
      }
    })
    el.addEventListener("paste", (event: any) => {
      if (event && event.clipboardData && event.clipboardData?.getData) {
        const pastedData = event.clipboardData?.getData("text")
        let isInvalidText = false
        for (let i = 0; i < pastedData.length; i++) {
          if (!allowedCharacters.includes(pastedData[i]) && !isInvalidText) {
            isInvalidText = true
            break
          }
        }
        if (isInvalidText) {
          event.preventDefault()
        }
      }
    })
  },
}

export default restrictCharsDirective
