import {defineStore} from 'pinia';
import {HttpService} from "@/helpers/http-service"
import {CONSTANT} from "@/helpers/constant";
import _, {parseInt} from "lodash";
import {RouteLocationNormalizedLoaded} from "vue-router";
import {getOrderCatalogueSearchQuery} from "@/helpers/common";
import {Product} from "@/types/product";
import {ApiResponse} from "@/types/apiResponse";

let httpService = new HttpService();

export const useOrderCatalogProductsStore = defineStore('salesForce.orderCatalog.products', {
    state: () =>
        ({
            products: [],
            errors: [],
            page: 1,
            totalPages: 0,
            limit: 15
        }),
    actions: {
        setPage(page: number) {
            this.page = page;
        },
        sortChildren(product: Product | null | undefined): Array<Product> {
            const result: Product[] = [];
            if (product) {
                if (!product.children) {
                    return result;
                }
                if (product.configuratorSettings) {
                    for (let configuration of product.configuratorSettings.sort((a: any, b: any) => a.position - b.position)) {
                        for (let child of product.children) {
                            if (!child.options || (child.options && child.options[0] && child.options[0].id !== configuration.optionId)) {
                                continue;
                            }
                            result.push(child);
                        }
                    }
                }

            }

            return result;
        },
        async fetch(route: RouteLocationNormalizedLoaded, searchQuery: string, autoCloseErrorsAfter?: number) {
            try {
                this.setPage(parseInt(route.query.page as string) || 1);
                const routeQuery: any = _.cloneDeep(route.query);
                if (routeQuery.hasOwnProperty('min-listPrice')) {
                    routeQuery['min-listPrice'] = parseFloat(routeQuery['min-listPrice']);
                }
                if (routeQuery.hasOwnProperty('max-listPrice')) {
                    routeQuery['max-listPrice'] = parseFloat(routeQuery['max-listPrice']);
                }
                const bodyParams = {
                    limit: this.limit,
                    page: this.page,
                    associations: {
                        cover: {
                            associations: {
                                media: {}
                            }
                        },
                        children: {
                            associations: {
                              eecomTpProduct: {},
                                options: {},
                                deliveryTime: {},
                                deliveryTimes: {
                                    associations: {
                                        deliveryTime: {}
                                    }
                                }
                            }
                        },
                        configuratorSettings: {},
                        options: {}
                    },
                    filter: [
                        {
                            type: "equals",
                            field: "active",
                            value: true
                        },
                        // {
                        //     type: "range",
                        //     field: "availableStock",
                        //     parameters:{
                        //         gt: 0
                        //     }
                        // },
                    ],
                    ...routeQuery,
                    p: route.query.page,
                    "disable-product-count": (!this.totalPages || parseInt(route.query.page as string) <= 1) ? null : true
                };
                const searchString = searchQuery ? searchQuery.trim() : '';
                let finalBodyParams: any = bodyParams;
                if (searchString) {
                    finalBodyParams = {...bodyParams, query: getOrderCatalogueSearchQuery(searchString)};
                }
                if (finalBodyParams) {
                    delete finalBodyParams.search;
                }
                const result: ApiResponse = await httpService.post(`${CONSTANT.API.ORDER_CATALOG.FETCH_PRODUCTS}`, finalBodyParams);
                if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
                    this.products = _.get(result, 'data.elements', []);
                    if (!this.totalPages || parseInt(route.query.page as string) <= 1) {
                        this.totalPages = Math.ceil(_.get(result, 'data.total', 1) / this.limit);
                    }
                } else {
                    this.products = [];
                }
            } catch (error) {
                this.errors = _.get(error, `errors`, []);
                if (!autoCloseErrorsAfter) {
                    return;
                }
                setTimeout(() => {
                    this.errors = []
                },)
            }
        },
    }
});
