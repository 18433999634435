import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import {useAuthStore, useCustomerStore} from "@/stores";
import _ from "lodash";
import {CONSTANT} from "@/helpers/constant";
import loginRoutes from "@/router/login.routes";
import customerRoutes from "@/router/customer.routes";
import salesForceRoutes from "@/router/sales-force.routes";
import campaginRoutes from "@/router/campagin.routes";
import accountRoutes from "@/router/account.routes";
import {getDefaultLanguage} from "@/internationalisation";
import {switchLocale} from "@/helpers/language";
import {useGtm} from '@gtm-support/vue-gtm';
import {IndexedDbService} from "@/helpers/indexed-db-service";
import {Language} from "@/types/language";

const routes: Array<RouteRecordRaw> = [
    {...loginRoutes},
    {...customerRoutes},
    {...salesForceRoutes},
    {...campaginRoutes},
    {...accountRoutes},
    {path: '/:pathMatch(.*)*', redirect: '/'}
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
})
const dbService = new IndexedDbService("languages", 1, [
  {
    name: "languageList",
    options: {keyPath: "id", autoIncrement: false},
  },
])
// Manage navigation guards
router.beforeEach(async (to, from) => {
  document.title = (to.meta.title as string) || process.env['VUE_APP_NAME'];
  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", (to.meta.description as string) || '');
  }

  const paramsLocale = getDefaultLanguage();
  const languageId = localStorage.getItem("teamportal-lang-id") as string;
  await dbService.connectWithIndexedDb();
  const currentLanguageData = await dbService.get("languageList", languageId);
  if (currentLanguageData && currentLanguageData.data) {
    const languageData: Language = JSON.parse(currentLanguageData.data);
    const code = languageData?.translationCode?.code;
    if (code && code !== paramsLocale) {
      localStorage.setItem("teamportal-lang", code);
      return switchLocale(code, languageId);
    }
  }
  return switchLocale(paramsLocale, languageId);

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/login/recovery', '/login/recovery/create-new'];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  const customerStore = useCustomerStore();
  let returnUrl = to.path;
  const customer = customerStore.customer;
  const isSalesRep = _.get(customer, 'customFields.eecom_tp_is_sales_rep', false);
  if (authRequired && (!authStore.contextToken || !customer)) {
    authStore.returnUrl = to.fullPath;
    returnUrl = CONSTANT.ROUTE.PATH.LOGIN;
  } else if ((!authRequired && authStore.contextToken && customer)) {
    returnUrl = isSalesRep ? CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE : CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER;
  } else if ((authRequired && authStore.contextToken && customer) && to.fullPath === CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE && !isSalesRep) {
    returnUrl = CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER;
  } else if ((authRequired && authStore.contextToken && customer) && to.fullPath === CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER && isSalesRep) {
    returnUrl = CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE;
  } else {
    return true;
  }
  return returnUrl;
});
