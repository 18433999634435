import i18n, {loadLocaleMessages, setDefaultLanguage, setI18nLanguage} from "@/internationalisation"

// export async function switchLocaleOld(locale: string, next = function () {
export async function switchLocale(locale: string, languageId?: string) {
  // const lang = setDefaultLanguage(locale)
  const lang = setDefaultLanguage(locale, languageId)

  // load locale messages
  if (!i18n.global.availableLocales.includes(lang)) {
    await loadLocaleMessages(i18n, lang)
  }

  // set i18n language
  setI18nLanguage(i18n, lang)

  return;
}
