import {CONSTANT} from "@/helpers/constant";

export default {
    path: CONSTANT.ROUTE.PATH.MY_ACCOUNT,
    name: CONSTANT.ROUTE.NAME.MY_ACCOUNT,
    component: () => import('@/views/account/MyAccount.vue'),
}



