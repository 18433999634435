import {nextTick, WritableComputedRef} from "vue"
import {createI18n, I18n, I18nOptions, Locale} from "vue-i18n"
import {HttpService} from "@/helpers/http-service"
import {getLanguageIsoCode} from "@/helpers/common";

// export function setDefaultLanguage(code: string) {
//   localStorage.setItem("teamportal-lang", code)

export function setDefaultLanguage(code: string, languageId?: string) {
  localStorage.setItem("teamportal-lang", code);
  if (languageId) {
    localStorage.setItem("teamportal-lang-id", languageId);
  }
  return code;
}

export function getDefaultLanguage() {
  const code = localStorage.getItem("teamportal-lang") || ""
  const defaultLanguage = JSON.parse(localStorage.getItem("defaultLanguage") as string);
  return code ? code : getLanguageIsoCode(defaultLanguage);
}

export function setupI18n(options: I18nOptions = {locale: "en-GB"}) {
  const i18n: I18n = createI18n(options)
  setI18nLanguage(i18n, options.locale ? options.locale : getDefaultLanguage())
  return i18n
}

export function setI18nLanguage(i18n: I18n, locale: Locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale
  } else {
    (i18n.global.locale as WritableComputedRef<string>).value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html")?.setAttribute("lang", locale)
}

export async function loadLocaleMessages(i18n: any, isoCode: string) {
  // load locale messages with dynamic import
  const http = new HttpService()
  const messages = await http.get(`eecom-teamportal/translation/${isoCode}.json`).then((res: any) => res.data)

  // set locale and locale message
  i18n.global.setLocaleMessage(isoCode, messages)
  return nextTick()
}

export default setupI18n({
  legacy: false,
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en-GB",
  warnHtmlMessage: false,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlInMessage: "off", // disable of the Detected HTML in message
})
