import { defineStore } from "pinia"
import { HttpService } from "@/helpers/http-service"
import { CONSTANT } from "@/helpers/constant"
import _ from "lodash"
import { ApiResponse } from "@/types/apiResponse"

const httpService = new HttpService()
const httpServiceWithoutLoader = new HttpService(false)

export const useProductRangeStore = defineStore({
  id: "productRange",
  state: () => ({
    ProductRanges: {},
    salesRepProductRanges: {},
    assortmentTeam: [],
    createdAssortmentTeam: {},
    isAssortmentTeamDeleted: false,
    isAssortmentTeamUpdated: false,
    assortmentDetails: {},
    assortmentProductDetails: {},
    changedProductRangeId: "",
  }),
  actions: {
    async fetchSalesRepProductRangeList(bodyParams: any, customerId: string) {
      try {
        const fetchSalesRepCustomerProductRangeUrl = CONSTANT.API.SALES_REP_PRODUCT_RANGE.replace("{id}", customerId)
        const result: ApiResponse = await httpService.post(fetchSalesRepCustomerProductRangeUrl, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            this.salesRepProductRanges = ProductRanges
          }
        } else {
          this.salesRepProductRanges = {}
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async updateProductRangeStatus(bodyParams: any, productRangeId: string) {
      try {
        const updateProductRangeStatusUrl = CONSTANT.API.UPDATE_PRODUCT_RANGE.replace("{id}", productRangeId)
        const result: ApiResponse = await httpService.patch(updateProductRangeStatusUrl, bodyParams)
        if (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            this.salesRepProductRanges = ProductRanges
          }
          return true
        } else {
          this.salesRepProductRanges = {}
          return false
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async deleteProductRange(productRangeId: string) {
      try {
        const deleteProductRangeUrl = CONSTANT.API.DELETE_PRODUCT_RANGE.replace("{id}", productRangeId)
        const result: ApiResponse = await httpService.delete(deleteProductRangeUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            this.salesRepProductRanges = ProductRanges
          }
        } else {
          this.salesRepProductRanges = {}
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async cloneProductRange(productRangeId: string, bodyParams: any) {
      try {
        const cloneProductRangeUrl = CONSTANT.API.CLONE_PRODUCT_RANGE.replace("{entity}", "eecom_tp_assortment").replace("{id}", productRangeId)
        const result: ApiResponse = await httpService.post(cloneProductRangeUrl, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            this.salesRepProductRanges = ProductRanges
          }
        } else {
          this.salesRepProductRanges = {}
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchAssortmentTeam() {
      try {
        const fetchAssortmentTeamUrl = CONSTANT.API.ASSORTMENT.FETCH_ASSORTMENT_TEAM
        const result: ApiResponse = await httpService.post(fetchAssortmentTeamUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const elements = _.get(result, "data.elements", null)
          if (elements) {
            this.assortmentTeam = elements
          }
        } else {
          this.assortmentTeam = []
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async deleteAssortmentTeam(assortmentProductId: string, teamId: string) {
      try {
        const deleteAssortmentTeamUrl = CONSTANT.API.ASSORTMENT.DELETE_ASSORTMENT_TEAM.replace("{id}", assortmentProductId).replace("{teamId}", teamId)
        const result: ApiResponse = await httpService.delete(deleteAssortmentTeamUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          this.isAssortmentTeamDeleted = true
        }
      } catch (error) {
        this.isAssortmentTeamDeleted = false
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async updateAssortmentTeam(bodyParams: any) {
      try {
        const result: ApiResponse = await httpService.post(CONSTANT.API.ASSORTMENT.UPDATE_ASSORTMENT_TEAM, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          this.isAssortmentTeamUpdated = true
        }
      } catch (error) {
        this.isAssortmentTeamUpdated = false
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async createAssortmentTeam(bodyParams: any) {
      try {
        const fetchAssortmentTeamUrl = CONSTANT.API.ASSORTMENT.CREATE_ASSORTMENT_TEAM
        const result: ApiResponse = await httpService.post(fetchAssortmentTeamUrl, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const element = _.get(result, "data.elements[0]", null)
          if (element) {
            this.createdAssortmentTeam = element
          }
        } else {
          this.createdAssortmentTeam = {}
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async setCoverAssortmentProductMedia(assortmentProductMediaId: any) {
      try {
        const patchAssortmentProductMediaUrl = CONSTANT.API.ASSORTMENT.SET_COVER_MEDIA.replace("{id}", assortmentProductMediaId)
        const result: ApiResponse = await httpService.get(patchAssortmentProductMediaUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async unSetCoverAssortmentProductMedia(id: any) {
      try {
        const patchAssortmentProductMediaUrl = CONSTANT.API.ASSORTMENT.UNSET_COVER_MEDIA.replace("{id}", id)
        const result: ApiResponse = await httpService.get(patchAssortmentProductMediaUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deleteAssortmentProductMedia(assortmentProductId: any) {
      try {
        const deleteAssortmentProductMediaUrl = CONSTANT.API.ASSORTMENT.DELETE_MEDIA.replace("{id}", assortmentProductId)
        const result: ApiResponse = await httpService.delete(deleteAssortmentProductMediaUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deleteAllAssortment(bodyParams: any) {
      try {
        const result: ApiResponse = await httpService.post(CONSTANT.API.ASSORTMENT.DELETE_ALL_ASSORTMENT, bodyParams)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deleteAssortmentById(assortmentProductId: any) {
      try {
        const deleteAssortmentProductByIdUrl = CONSTANT.API.ASSORTMENT.DELETE_ASSORTMENT_BY_ID.replace("{id}", assortmentProductId)
        const result: ApiResponse = await httpService.delete(deleteAssortmentProductByIdUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async cloneAssortmentProducts(assortmentProductId: string, bodyParams: any) {
      try {
        const cloneProductRangeUrl = CONSTANT.API.ASSORTMENT.CLONE_ASSORTMENT.replace("{entity}", CONSTANT.BODY_PARAMS.ENTITY.EECOM_TP_ASSORTMENT_PRODUCT).replace("{id}", assortmentProductId)
        return await httpService.post(cloneProductRangeUrl, bodyParams)
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
        return errors
      }
    },
    async setAssortmentProductVisibility(bodyParams: any) {
      try {
        const result: ApiResponse = await httpService.post(CONSTANT.API.SYSTEM_CONTEXT.SELECTED_VISIBILITY, bodyParams)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deselectAssortmentProductVisibility(bodyParams: any) {
      try {
        const result: ApiResponse = await httpService.post(CONSTANT.API.SYSTEM_CONTEXT.UNSELECTED_VISIBILITY, bodyParams)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
        return false
      }
    },
    async fetchCustomerAssortmentDetailsByCustomerId(customerId: string, bodyParams: any, isDisplayLoader: boolean) {
      try {
        const fetchCustomerAssortmentDetailsByCustomerIdURL = CONSTANT.API.ASSORTMENT.ASSORTMENT_DETAILS_BY_CUSTOMER_ID.replace("{customerId}", customerId)
        let result: ApiResponse
        if (isDisplayLoader) {
          result = await httpService.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        } else {
          result = await httpServiceWithoutLoader.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        }
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.assortmentDetails = _.get(result, "data", {})
        } else {
          this.assortmentDetails = {}
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchCustomerAssortmentProductDetailsByAssortmentId(assortmentId: string, bodyParams: any, withLoader?: boolean) {
      try {
        const fetchCustomerAssortmentProductDetailsByAssortmentIdURL = CONSTANT.API.ASSORTMENT.ASSORTMENT_PRODUCT_DETAILS_BY_ASSORTMENT_ID.replace("{assortmentId}", assortmentId)
        let result: any
        if (withLoader) {
          result = await httpService.post(fetchCustomerAssortmentProductDetailsByAssortmentIdURL, bodyParams)
        } else {
          result = await httpServiceWithoutLoader.post(fetchCustomerAssortmentProductDetailsByAssortmentIdURL, bodyParams)
        }
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.assortmentProductDetails = _.get(result, "data", {})
        } else {
          this.assortmentProductDetails = {}
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchAssortmentDetailsByCustomerId(customerId: string, bodyParams: any, isDisplayLoader: boolean) {
      try {
        const fetchCustomerAssortmentDetailsByCustomerIdURL = CONSTANT.API.ASSORTMENT.ASSORTMENT_DETAILS_BY_CUSTOMER_ID.replace("{customerId}", customerId)
        if (isDisplayLoader) {
          return await httpService.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        } else {
          return await httpServiceWithoutLoader.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        }
      } catch (error) {
        console.error(error)
        return error
      }
    },
  },
})
