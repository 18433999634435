import {defineStore} from 'pinia';
import {HttpService} from "@/helpers/http-service"
import {CONSTANT} from "@/helpers/constant";
import _ from "lodash";

const httpService = new HttpService();

export const useCmsStore = defineStore({
    id: 'cms',
    state: () =>
        ({
            cms: {},
            errorMessage: '',
        }),
    actions: {
        async fetchCms() {
            try {
                const result: any = await httpService.get(`${CONSTANT.API.IMPRINT_PAGE}`);
                if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
                    const data = _.get(result, 'data', null);
                    if (data) {
                        this.cms = data;
                    }
                }
            } catch (error) {
                const errors = _.get(error, `response.data.errors`, []);
                if (errors && errors.length > 0) {
                    // @ts-ignore
                    this.errorMessage = errors[0].detail;
                }
                console.error(error);
            }
        },
    }
});