export const ImageBasePath = {
  Small: process.env.VUE_APP_CDN_URL + "/cdn-cgi/image/format=auto,width=250",
  Medium: process.env.VUE_APP_CDN_URL + "/cdn-cgi/image/format=auto,width=600",
  Large: process.env.VUE_APP_CDN_URL + "/cdn-cgi/image/format=auto,width=1400",
}

class ImageService {
  getSmallImage(imagePath: string): string {
    return ImageBasePath.Small + this.cleanPath(imagePath)
  }

  getMediumImage(imagePath: string): string {
    return ImageBasePath.Medium + this.cleanPath(imagePath)
  }

  getLargeImage(imagePath: string): string {
    return ImageBasePath.Large + this.cleanPath(imagePath)
  }

  protected cleanPath(imagePath: string): string {
    const url = new URL(imagePath)
    return imagePath.replace(url.origin, "")
  }
}

const imageService = new ImageService()

const cdnEnabled = process.env.VUE_APP_CDN_ENABLE === "true"

export default new Proxy(imageService, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get(target: ImageService, prop: string, receiver): any {
    // console.log('cdnEnabled', process.env.VUE_APP_CDN_ENABLE);
    if (!cdnEnabled) {
      return function (...args: any[]) {
        return args[0]
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return Reflect.get(...arguments)
  },
})
