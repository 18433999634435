import { createApp } from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import { router } from "./router"

import "./registerServiceWorker"
import i18n from "@/internationalisation"

import CountryFlag from 'vue-country-flag-next'
import VueLoading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "vue-select/dist/vue-select.css"
import "@vueform/multiselect/themes/default.css"

import VSelect from "vue-select"
import VueFeather from "vue-feather"
import Multiselect from "@vueform/multiselect"
import Button from "@/components/BaseButton.vue"

import restrictCharsDirective from "@/directives/directives"

// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')
import { CONSTANT } from "@/helpers/constant"
const app = createApp(App)
  .component("v-select", VSelect)
  .component("vue-multiselect", Multiselect)
  .component(VueFeather.name, VueFeather)
  .component('country-flag', CountryFlag)
  .component("Button", Button)
  .directive("restrict-chars-directive", restrictCharsDirective)
  .use(createPinia())
  .use(VueLoading, CONSTANT.VUE_LOADER_OPTIONS)
  .use(router)
  .use(i18n)
app.mount("#app")
