import {CONSTANT} from "@/helpers/constant";

export default {
    path: CONSTANT.ROUTE.PATH.CAMPAGIN,
    name: CONSTANT.ROUTE.NAME.CAMPAGIN,
    redirect: CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE,
}



